<template>
  <div>
    <div class="col-8 text-center">
      <i v-if="isPageLoading" class="fa fa-cog fa-spin fa-4x"></i>
      <div v-if="isPageLoading" id="overlay"></div>
    </div>

    <table class="table">
      <tbody>
        <tr>
          <td>ID</td>
          <td>{{ member.Id }}</td>
        </tr>
        <tr>
          <td>Позывной</td>
          <td>{{ member.Callsign }}</td>
        </tr>
        <tr>
          <td>Группировка</td>
          <td>{{ member.Fraction.Title }}</td>
        </tr>
        <tr>
          <td>Ранг</td>
          <td>{{ getRank(member.Rank) }}</td>
        </tr>
        <tr>
          <td>Деньги</td>
          <td>{{ member.Money }} руб.</td>
        </tr>
        <tr>
          <td>Что выдать</td>
          <td>
            <ol
              v-if="pendingList.length > 0"
              style="text-align: left !important;"
            >
              <li v-for="index in pendingList" :key="index">
                {{ index }}
              </li>
            </ol>
            <div v-if="pendingList.length === 0">Ничего</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import VueGallery from "vue-gallery";
import API from "../api/stalk.net";

export default {
  name: "MemberData",
  props: {
    baseURL: String,
    member: Object,
    images: Array,
    getRank: Function
  },
  data() {
    return {
      isPageLoading: false,
      index: null
    };
  },
  components: {
    gallery: VueGallery
  },
  computed: {
    pendingList: function() {
      return (this.member.LegendMaster.length > 0) ? this.member.LegendMaster.split(",") : [];
    }
  },
  filters: {},
  methods: {
    catchError(e) {
      let self = this;

      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data);
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              API.private.logout();
              self.$router.replace("/login");
            }
            break;
        }
        self.showError({ message: e.response });
      }
      self.isPageLoading = false;
    }
  }
};
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  -moz-opacity: 0.5;
  filter: alpha(opacity=50);
  opacity: 0.5;
  z-index: 1001;
}
</style>
